<template>
  <svg
    id="logo"
    width="74"
    height="32"
    viewBox="0 0 74 32"
    fill="none"
  >
    <g clip-path="url(#clip0_3167_15372)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.6049 0H64.207V1.29795V2.5959H66.6049V0Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.9998 2.80957H71.6089V3.99706V5.19145H73.9998V2.80957Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.5256 6.48242H0V12.9653H7.40208V31.3437H15.8884V12.9653H28.4893L25.0281 16.5346V21.5055C29.6222 21.7679 30.2547 21.8714 30.6856 22.2166C31.1027 22.5204 31.2278 22.8242 31.2278 23.4455C31.2278 24.0669 31.0957 24.3569 30.6161 24.7228C30.074 25.1094 29.6987 25.1784 27.6762 25.1577C25.6953 25.1577 25.1045 25.068 23.7145 24.5502C22.8248 24.2257 21.8865 23.9219 21.6502 23.8598C21.2819 23.77 21.0386 24.1428 19.9057 26.6076L19.8223 26.794C19.1412 28.2921 18.5782 29.5279 18.5365 29.5901C18.5156 29.6522 19.0369 29.9767 19.7319 30.3012C20.427 30.605 21.3861 30.9709 21.8657 31.1021C21.9699 31.1228 22.1089 31.1573 22.2758 31.1918C22.8387 31.323 23.6797 31.5094 24.3678 31.6613C25.3061 31.8339 27.1132 31.9858 28.5658 31.9858C30.2617 31.9858 31.4919 31.8684 32.2425 31.6406C32.875 31.468 34.0287 31.0537 34.8141 30.7292C35.8358 30.3219 36.6004 29.8179 37.5595 28.8721C38.5395 27.8986 39.0191 27.2082 39.3874 26.2555C39.7349 25.3441 39.867 24.5018 39.867 23.4455C39.867 22.4721 39.7141 21.5193 39.4291 20.7461C39.1025 19.9245 38.6437 19.2341 37.8862 18.5437C37.2745 17.9569 36.2528 17.2665 35.6412 16.9627C35.0087 16.6589 34.3346 16.4242 34.1608 16.4242C33.987 16.4242 34.8767 15.3886 36.1625 14.1113L38.5117 11.7778V6.48242H38.5256Z"
        fill="#FF8700"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.8159 4.10742H66.6047V8.86427H61.8159V6.4824V4.10742Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.8765 6.48242H51.3698V15.3471L59.314 15.2436L59.4182 10.3763H64.207V18.806H67.6891V31.3506H59.4182V22.2719H51.3698V31.3506H42.8765V18.9165V6.48242Z"
        fill="black"
      />
      <path
        d="M70.3025 6.48242H67.9116V8.85739H70.3025V6.48242Z"
        fill="black"
      />
      <path
        d="M71.3936 10.377H65.5137V17.2948H71.3936V10.377Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3167_15372">
        <rect
          width="74"
          height="32"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
